import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AdminUser } from '@memberspot/shared/model/admin-user';
import { FireAuthState, initialAuthState, RoleState } from 'akita-ng-fire';

import { Roles } from './auth.model';

export interface AuthState extends FireAuthState<AdminUser>, RoleState<Roles> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(initialAuthState);
  }
}
